import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./lng/en.json";
import uk from "./lng/uk.json";

i18next.use(initReactI18next).init({
	resources: {
        ua: {
			translation: uk,
		},
		en: {
			translation: en,
		}
		
	},
	 lng: localStorage.getItem("lng") || "ua",


});

export default i18next;