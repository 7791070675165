import React, { useEffect, useState } from 'react'

import classes from './Footer.module.scss'
import {Link} from 'react-router-dom'
import logo from '@/assets/img/logo_footer.svg'
import fb from '@/assets/img/fb.svg'
import insta from '@/assets/img/insta.svg'
import tg from '@/assets/img/tg.svg'
import tiktok from '@/assets/img/tiktok.svg'
import youtube from '@/assets/img/youtube.svg'
import submit from '@/assets/img/submit_arrow.svg'
import { useTranslation  } from 'react-i18next'

const Footer: React.FC = () => {
      const {t, i18n } = useTranslation()
      const [loaded, setLoaded] = useState<boolean>(false);
      
      console.log(i18n.language);

      useEffect(()=>{
        console.log(i18n.language);
        setLoaded(true)
      }, [i18n])

if(loaded)  return (
    <footer>
      <div className={classes.footer}>
      
      <div className={`${classes.col} ${classes.col_f_1}`}>
        <a href={`${i18n.language === "en" ? '/en' : '/'}`}><img src={logo} alt="" className={classes.footer_logo} /></a>
        
      </div>


      <div className={`${classes.col} ${classes.col_f_2}`}>
        <p className={classes.footer_capture}>{t("professional care")}</p>
        <ul>
          <li><Link to={`${i18n.language === "en" ? '/en' : ''}/category/profesijni-nabori`}>{i18n.language === "en" ? 'Professional Kits' : 'професійні набори'}</Link></li>
          <li><Link to={`${i18n.language === "en" ? '/en' : ''}/category/bazovi-preparati`}>{i18n.language === "en" ? 'Essential Treatments' : 'базові препарти'}</Link></li>
          <li><a href={`${i18n.language === "en" ? '/en' : ''}/category/antioksidantni-pilingi`}>{i18n.language === "en" ? 'Antioxidant Peelings' : 'антиоксидантні пілінги'}</a></li>
        </ul>

      </div>


      <div className={`${classes.col} ${classes.col_f_5}`}>
      <p className={classes.footer_capture}>{t("home care")}</p>
        <ul>
          <li> <Link to={`${i18n.language === "en" ? '/en' : '/'}/category/liniya-aqya-o2xy`}>{i18n.language === "en" ? '' : 'ЛІНІЯ'} <span>AQUA O2XY</span>{i18n.language === "en" ? 'LINE' : ''} </Link></li>
          <li><Link to={`${i18n.language === "en" ? '/en' : '/'}/category/liniya-3r-ceramide`}>{i18n.language === "en" ? '' : 'ЛІНІЯ'} <span>3R CERAMIDE</span>{i18n.language === "en" ? 'LINE' : ''}</Link></li>
          <li><Link to={`${i18n.language === "en" ? '/en' : '/'}/category/liniya-b-biotic`}>{i18n.language === "en" ? '' : 'ЛІНІЯ'} <span>β-BIOTIC</span>{i18n.language === "en" ? 'LINE' : ''}</Link></li>
          <li><Link to={`${i18n.language === "en" ? '/en' : '/'}/category/liniya-renaissance`}>{i18n.language === "en" ? '' : 'ЛІНІЯ'} <span>RENAISSANCЕ</span>{i18n.language === "en" ? 'LINE' : ''}</Link></li>
          
        </ul>
      </div>

      
      <div className={`${classes.col} ${classes.col_f_3}`}>
        <p className={classes.footer_capture}>{t("cef comunity")}</p>
        <ul>
          <li><a href="#">{i18n.language === "en" ? 'Brand Philosophy' : 'філософія бренду'}</a></li>
          <li><a href="#">{i18n.language === "en" ? 'Partner Program' : 'Партнерська програма'}</a></li>
        
        </ul>

        <p className={classes.footer_capture}>{t("contacts")}</p>
        <ul>
          <li><a href={`${i18n.language === "en" ? '/en' : ''}/contact`}>{t("contacts")}</a></li>
        </ul>

      </div>
      <div className={`${classes.col} ${classes.col_f_4}`}>
        <p className={classes.footer_capture}>{i18n.language === "en" ? 'Stay tuned' :  `Залишайтесь на зв'язку`}</p>
        <form className={classes.footer_form}> 
          <span>{i18n.language === "en" ? 'Phone number' :  `Номер телефону`}</span>
          <input type="tel" placeholder='380935677444' />
          <button type='submit'><img src={submit} alt="" /></button>
        </form>

        <div className={classes.footer_descr}>*By checking the above box you are agreeing to receive SMS communications from Ceflab. Please refer to our Privacy Policy and Terms of Use for more details.</div>

        <div className={classes.socs}>
          <a href="https://www.instagram.com/ceflaboratory"><img src={insta} alt="CEF lab" /></a>
          <a href="https://www.facebook.com/share/15weayXMQW/?mibextid=wwXIfr"><img src={fb} alt="CEF lab" /></a>
          <a href="https://www.youtube.com/@CEFLaboratoryofficial"><img src={youtube} alt="CEF lab" /></a>
          {/* <a href="#"><img src={tg} alt="" /></a>
          <a href="#"><img src={youtube} alt="" /></a>
          <a href="#"><img src={tiktok} alt="" /></a> */}
        </div>
      </div>
      
    </div>

    <div className={classes.socsMob}>
          <a href="https://www.instagram.com/ceflaboratory?igsh=cDgzcmN1ZThoa2Nt"><img src={insta} alt="CEF lab" /></a>
          <a href="https://www.facebook.com/share/15weayXMQW/?mibextid=wwXIfr"><img src={fb} alt="CEF lab" /></a>
          <a href="https://www.youtube.com/@CEFLaboratoryofficial"><img src={youtube} alt="CEF lab" /></a>
          {/* <a href="#"><img src={tg} alt="" /></a>
          <a href="#"><img src={youtube} alt="" /></a>
          <a href="#"><img src={tiktok} alt="" /></a> */}
        </div>
        
     <p className={classes.copyrightMob}>
      <span>© 2022-2025 All rights reserved </span></p> 
    </footer>
    
  )
}
export default Footer