import React, { useState, useEffect } from 'react'
import classes from './ProductCard.module.scss'

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/redux/store'
import { Arrow, QuickViewModal } from '@/Components'
import { openQuick, closeQuick, setQcard } from '@/redux/slices/userSlice'

import { useTranslation  } from 'react-i18next'

type metaProps = {
  id: number,
  key: string,
  value: string,
}

type ProductCardProps = {
  id: number,
  sku: string,
  slug: string,
  name: string,
  img: string,
  description: string,
  meta: metaProps[],
  price: number,
  type?: string ,
  topQuick: boolean,
  product: any
}


const ProductCard: React.FC<ProductCardProps> = ({ id, sku, slug, name, img, description, meta, price, type, topQuick, product }) => {
    const {t, i18n } = useTranslation()
  const [activeBeltModal, setActiveBeltModal] = useState<boolean>(false)
  const [ml, setMl] = useState<string>('')
  const [short, setShort] = useState<string>('')
  const [productName, setProductName] = useState<string>('')
  const [typeProduct, setTypeProduct] = useState<string>('')
  const dispatch = useAppDispatch()

  useEffect(() => {
    const ml_value: any = product?.meta_data?.find((obj: any) => obj.key === `obm${i18n.language === "en" ? '_eng' : ''}`)
    const product_name:any = product?.meta_data?.find((obj: any) => obj.key === `nazva_tovaru${i18n.language === "en" ? '_eng' : ''}`)
    
    const short_value: any = product?.meta_data?.find((obj: any) => obj.key === `osnovni_ingridinti${i18n.language === "en" ? '_eng' : ''}`)
    const product_type: any = product?.meta_data?.find((obj: any) => obj.key === 'tip_tovaru')

    setMl(ml_value?.value)
    setShort(short_value?.value)
    setTypeProduct(product_type?.value)
    setProductName(product_name?.value)

  }, [product])

  const callTopQuick = () => {
    dispatch(setQcard({
      id,
      sku,
      slug,
      name,
      img,
      description,
      ml,
      price
    }))
    dispatch(openQuick())
  }


  return (
    <>
      <div className={classes.productCard}>
        <div className={classes.productCard_Img}>
          <img src={img} alt="" />
        </div>
        <Link to={`/product/${slug}`} className={classes.productCard_name}>{productName}</Link>
        <div className={classes.productCard_description}>{short.substring(0, 139)}...</div>
        <div className={classes.productCard_ml}><span>{i18n.language === "en" ? 'Volume' : 'Об‘єм'}: </span> {ml}</div>


        {(type !== 'profesijni-nabori' && price > 0) ? (<div className={classes.productCard_price}>{price} uah</div>) : ''}

        <Link to={`/product/${slug}`} className={`${classes.blackBtn} addToCart`} >
          { (i18n.language === "en") ? (
            (type === "profesijni-nabori") ? 'get quotation' : 'order'
          ) : (
            (type === "profesijni-nabori") ? 'дізнатись ціну' : 'замовити'
          )}
         
         <Arrow /> </Link>
        <button className={classes.whiteBtn} onClick={() => { topQuick ? callTopQuick() : setActiveBeltModal(true) }}>{i18n.language === "en" ? 'quick view' : 'швидкий перегляд'} <Arrow /></button>

      </div>


      <QuickViewModal active={activeBeltModal} closeModal={() => { setActiveBeltModal(false) }} product={{ id, sku, slug, name: productName, img, description, ml, price, typeProduct }} />
    </>

  )
}
export default ProductCard; 
