import React, { useEffect, useRef, useState } from 'react'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ln1 from "@/assets/img/ln1.jpg"
import ln2 from "@/assets/img/ln2.jpg"
import ln3 from "@/assets/img/ln3.jpg"
import ln4 from "@/assets/img/u2.jpg"

import prev from "@/assets/img/l_prod_slider.svg"
import next from "@/assets/img/r_prod_slider.svg"

import { CustomArrowProps, default as Slider } from "react-slick";
import { Link } from 'react-router-dom';
import Arrow from '@/Components/Arrow';
import { useTranslation  } from 'react-i18next'

// import Slider from 'react-slick'





const NextArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <img src={next} alt="" />
    </div>
  );
};
const PrevArrow: React.FC<CustomArrowProps> = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <img src={prev} alt="" />
    </div>
  );
};

type TSliderProps = {
  adaptiveHeight?: false,
  className?: string,
  infinite?: boolean,
  dots?: boolean,
  autoplay?: boolean,
  centerMode?: boolean,
  centerPadding?: string,
  speed?: number,
  autoplaySpeed?: number,
  slidesToScroll?: number,
  slidesToShow?: number,
  nextArrow?: JSX.Element,
  prevArrow?: JSX.Element,
  beforeChange?: (currentSlide: number, nextSlide: number) => void,
  responsive?: { breakpoint: number; settings: any }[]

}




const MainCarousel: React.FC = () => {
  const [imageIndex, setImageIndex] = useState<number>(0);
const [loaded, setLoaded] = useState<boolean>(false);
  const sliderRef = React.useRef<Slider>(null);
    const {t, i18n } = useTranslation()
  const settings: TSliderProps = {
    className: 'mainPage_carousel',
    infinite: true,
    dots: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    //beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true,
          // className: "center",
          // slidesToShow: 1,
          centerPadding: "0",
          centerMode: true,
        },
      },
    ],
  };
     useEffect(()=>{
        console.log(i18n.language);
        setLoaded(true)
      }, [i18n])

  if(loaded) return (

    <Slider {...settings} ref={sliderRef} >

      <div className="unic__Item">
        <img src={ln1} alt="" />
        <p>{i18n.language === "en" ? '' : 'ЛІНІЯ'} AQUA O2XY {i18n.language === "en" ? 'LINE' : ''}</p>
        <div>{i18n.language === "en" ? 'An innovative skincare line for all skin types, designed to correct the first signs of aging and provide intense hydration, enriched with advanced antioxidants and comprehensive moisturizers.' : 'Інноваційна лінія для всіх типів шкіри для корекції перших проявів старіння та екстразволоження, насичений сучасними антиоксидантами та комплексними зволожувачами.'}</div>
        <a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-aqya-o2xy`} className="moreBtn">{i18n.language === "en" ? 'view line' : 'до ЛІНІЇ'}  <Arrow /></a>
      </div>
      <div className="unic__Item">

        <img src={ln2} alt="" />
        <p>{i18n.language === "en" ? '' : 'ЛІНІЯ'} 3R CERAMIDE {i18n.language === "en" ? 'LINE' : ''}</p>
        <div>{i18n.language === "en" ? 'A skincare line based on three forms of retinol, suitable for all seasons, designed to correct signs of aging, acne, post-acne, and pigmentation.' : 'Лінія на основі 3 форм ретинолу без сезонних обмежень для корекції вікових змін шкіри та проявів акне, постакне, пігментації.'}</div>
        <a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-3r-ceramide`} className="moreBtn">{i18n.language === "en" ? 'view line' : 'до ЛІНІЇ'} <Arrow /></a>
      </div>
      <div className="unic__Item">

        <img src={ln3} alt="" />
        <p>{i18n.language === "en" ? '' : 'ЛІНІЯ'} β-BIOTIC {i18n.language === "en" ? 'LINE' : ''}</p>
        <div>{i18n.language === "en" ? 'Highly effective treatments for oily and combination skin, suitable for all ages. A comprehensive correction technology based on azelaic acid and niacinamide.' : 'Високоефективні препарати для жирної та комбінованої шкіри без вікових обмежень. Комплексна технологія корекції на основі азелаїнової кислоти та ніацинаміду.'}</div>
        <a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-b-biotic`} className="moreBtn">{i18n.language === "en" ? 'view line' : 'до ЛІНІЇ'} <Arrow /></a>
      </div>

      <div className="unic__Item">
        <img src="https://api.apicef.space/wp-content/uploads/2025/03/cef-catalog5504-scaled-1.jpg" alt="" />
        <p>{i18n.language === "en" ? '' : 'ЛІНІЯ'} RENAISSANCE {i18n.language === "en" ? 'LINE' : ''}</p>
        <div>{i18n.language === "en" ? 'Advanced skincare line for mature skin to smooth wrinkles and provide deep hydration, nourishment, and replenishment.' : 'Передова лінія для догляду за зрілою шкірою для розгладження зморшок та вираженого зволоження, живлення та насичення шкіри.'}</div>
        <a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-renaissance`} className="moreBtn">{i18n.language === "en" ? 'view line' : 'до ЛІНІЇ'} <Arrow /></a>
      </div>
      
    </Slider>
  )
}
export default MainCarousel;