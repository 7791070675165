import React,{useEffect, useState} from 'react'



import { Link, useLocation, useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux'
import {selectCart} from '@/redux/slices/cartSlice'
import Select from 'react-select'
import {Search,CartModal} from "@/Components";

import prof1 from '@/assets/img/prof1.jpg'
import prof2 from '@/assets/img/prof2.jpg'
import prof3 from '@/assets/img/prof3.jpg'
import dom1 from '@/assets/img/dom1.jpg'
import dom2 from '@/assets/img/dom2.jpg'
import dom3 from '@/assets/img/dom3.jpg'
import dom4 from '@/assets/img/dom4.jpg'
import mobBut from '@/assets/img/mobMnu.svg'
import close from '@/assets/img/closeModal.svg'
import search from '@/assets/img/search.svg'
import cart from '@/assets/img/cart.svg'
import logo from '@/assets/img/logo.svg'
import { useTranslation  } from 'react-i18next'
import classes from "./Header.module.scss"

const Header: React.FC = () => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [currentLang, setCurrentLang] = useState<string>('');
    const [clicked, setClicked] = useState<boolean>(false)
    const[activeBeltModal, setActiveBeltModal] = useState<boolean>(false)
    const [isMobileActive, setIsMobileActive] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const {items} = useSelector(selectCart)
    const {t, i18n } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const optionsLangs = [
        { value: "en", label: "EN"},
        { value: "ua", label: "UA"}
      ]

      const redirect = (newLang: string) => {
  
        if(localStorage.getItem("lang") === newLang){return}
        
        const to = '/'+newLang
        let path = location.pathname
         
        if(newLang === 'ua' && localStorage.getItem('lang') === 'ua') return
        if(newLang === 'en' && localStorage.getItem('lang') === 'en') return
        if(newLang === 'ua' ){
            path = path.replace('/en/', '' )
        }else{
            path = to +  path
        }

        navigate(path)
        console.log(i18n.language)
        window.location.reload()
      }
      
      const changeLng = (e: any) => {
        console.log(e)
        redirect(e.value)
        i18n.changeLanguage(e.value)
        document.documentElement.setAttribute('lang', e.value)
        localStorage.setItem("lang", e.value)
      }


      useEffect(()=>{
        console.log("header");
        console.log(i18n.language);
        setLoaded(true)
        if(localStorage.getItem("lang")){
            document.documentElement.setAttribute('lang', localStorage.getItem("lang") || '')
            i18n.changeLanguage(localStorage.getItem("lang") || undefined)
            return;
        }else{
            if(location.pathname.includes('/en')){
                localStorage.setItem("lang", "en")
                setCurrentLang("EN")
                i18n.changeLanguage("en")
                //redirect("en")
            }
            else{
                localStorage.setItem("lang", "ua")
                setCurrentLang("UA")
                i18n.changeLanguage("ua")
                //redirect("ua")
            }
        }
      
      }, [i18n])


      if(loaded) return (
        <>
        <header className={classes.header}>
            <button className={classes.mobMnu} onClick={()=> setIsMobileActive(true)}>
                <span></span>
                <span></span>    
            </button>
            <Link to={`${i18n.language === "en" ? '/en' : '/'}`} className={classes.logo}><img src={logo}  alt="" /></Link>

            <nav>
                <ul>
                    <li className={classes.parent_mnu}>
                        <a href="#">{t("professional care")}</a>
                        <div className={classes.child_mnu}>
                            <div className={classes.child_mnu_wrapper}>
                                <div className={classes.child_mnu_Item}>
                                    <img src={prof1} alt="" />
                                    <a href={`${i18n.language === "en" ? '/en' : ''}/category/profesijni-nabori`}>{i18n.language === "en" ? 'Professional Kits' : 'професійні набори'}</a>
                                    <p>{i18n.language === "en" ? 'Ready-to-use kits for professional treatments' : 'Готові набори для проведення професійних процедур'}</p>
                                </div>
                                <div className={classes.child_mnu_Item}>
                                    <img src={prof2} alt="" />
                                    <a href={`${i18n.language === "en" ? '/en' : ''}/category/bazovi-preparati`}>{i18n.language === "en" ? 'Essential Treatments' : 'базові препарти'}</a>
                                    <p>{i18n.language === "en" ? 'Essential products for professional treatments' : 'Базові препарати для профедення професійних процедур'}</p>
                                </div>
                                <div className={classes.child_mnu_Item}>
                                    <img src={prof3} alt="" />
                                    <a href={`${i18n.language === "en" ? '/en' : ''}/category/antioksidantni-pilingi`}>{i18n.language === "en" ? 'Antioxidant Peelings' : 'антиоксидантні пілінги'}</a>
                                    <p>{i18n.language === "en" ? 'New!' : 'Новинка!'}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className={classes.parent_mnu}>
                        <a href="#">{t("home care")}</a>
                        <div className={classes.child_mnu}>
                            <div className={classes.child_mnu_wrapper}>
                                <div className={classes.child_mnu_Item}>
                                    <img src={dom1} alt="" />
                                    <a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-renaissance`}>{i18n.language === "ua" && 'ЛІНІЯ'} RENAISSANCE {i18n.language === "ua" && 'LINE'}</a>
                                    <p>{i18n.language === "en" ? 'Line for mature skin to smooth wrinkles, deeply hydrate, and nourish.' : 'Лінія для зрілої шкіри, що розгладжує зморшки, глибоко зволожує та живить.'}</p>
                                </div>
                                <div className={classes.child_mnu_Item}>
                                    <img src={dom2} alt="" />
                                    <a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-3r-ceramide`}>{i18n.language === "ua" && 'ЛІНІЯ'} 3R CERAMIDE {i18n.language === "ua" && 'LINE'}</a>
                                    <p>{i18n.language === "en" ? 'Retinol-based line for correcting aging signs, acne, post-acne, and pigmentation.' : 'Лінія з 3-ма формами ретинолу для корекції вікових змін, акне, постакне, пігментації.'}</p>
                                </div>
                                <div className={classes.child_mnu_Item}>
                                    <img src={dom3} alt="" />
                                    <a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-b-biotic`}>{i18n.language === "ua" && 'ЛІНІЯ'} β-BIOTIC {i18n.language === "ua" && 'LINE'}</a>
                                    <p>{i18n.language === "en" ? 'Line for oily and combination skin with azelaic acid and niacinamide.' : 'Лінія для жирної та комбінованої шкіри з азелаїновою кислотою та ніацинамідом.'}</p>
                                </div>
                                <div className={classes.child_mnu_Item}>
                                    <img src={dom4} alt="" />
                                    <a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-aqya-o2xy`}>{i18n.language === "ua" && 'ЛІНІЯ'} AQYA O2XY {i18n.language === "ua" && 'LINE'}</a>
                                    <p>{i18n.language === "en" ? 'Line for all skin types to correct early aging signs and provide deep hydration.' : 'Лінія для всіх типів шкіри для корекції перших зморшок та екстразволоження.'}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className={classes.parent_mnu}>
                        <Link to={`${i18n.language === "en" ? '/en' : ''}/comunity`}>{t("cef comunity")}</Link>

                        {/* <div className={classes.child_mnu}>
                            <div className={classes.child_mnu_wrapper}>
                               
                                <div className={classes.child_mnu_Item}>
                                    <a href="/" className={classes.child_title_mnu}>ФІЛОСОФІЯ БРЕНДУ</a>
                                    <a href="/" className={classes.child_title_mnu}>УНІКАЛЬНА ТЕХНОЛОГІЯ</a>
                                </div>
                                <div className={classes.child_mnu_Item}>
                                    <p className={classes.child_title_mnu}>ФІЛОСОФІЯ БРЕНДУ</p>
                                    <a href="/" className={classes.child_mini_link}>Співпраця з косметологами</a>
                                    <a href="/" className={classes.child_mini_link}>Співпраця з салонами</a>
                                    <a href="/" className={classes.child_mini_link}>Стати партнером</a>
                                    <a href="/" className={classes.child_mini_link}>Міждународна співпраця</a>
                                </div>
                                <div className={classes.child_mnu_Item}>
                                    <p className={classes.child_title_mnu}>ЗАХОДИ</p>
                                    <a href="/" className={classes.child_mini_link}>Семінари</a>
                                    <a href="/" className={classes.child_mini_link}>Навчальні вебінари</a>
                                    <a href="/" className={classes.child_mini_link}>Івенти</a>
                                </div>
                                <div className={classes.child_mnu_Item}>
                                    <p className={classes.child_title_mnu}>БЛОГ</p>
                                    <a href="/" className={classes.child_mini_link}>Акції</a>
                                    <a href="/" className={classes.child_mini_link}>Новини</a>
                                </div>
                                
                            </div>
                        </div> */}


                    </li>
                    <li><Link to={`${i18n.language === "en" ? '/en' : ''}/contact`}>{t("contacts")}</Link></li>
                </ul>
            </nav>

            <div className={classes.header_icons}>
                {/* <img src={search} alt="" onClick={()=>setShowSearch(true)} /> */}
                <button className={classes.cart_ico} onClick={()=>setActiveBeltModal(true)}><img src={cart} alt="" /> <span>{items.length}</span></button>
            
                <div className="langs">
                <Select 
                    options={optionsLangs}
                    onChange={changeLng}
                    placeholder={localStorage.getItem('lang') || currentLang}

                    // defaultInputValue={{ label: localStorage.getItem("lng") || 'en', value: localStorage.getItem("lng") || 'en' }}

                    className="lng-select"
                
                    styles={{
                        control: (baseStyles, state) => ({
                        ...baseStyles,
                        boxShadow: 'none',
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            border: "none",
                            boxShadow: "none",
                            backgroundColor: "#131313"
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            color: "#fff",
                            fontSize: 16,
                            backgroundColor: state.isFocused ? "#333" : "#1a1a1a",
                            cursor: "pointer",
                            borderColor: "#333",
                            outline: "none",
                            border: "none"

                    })
                    }}
                    classNamePrefix="react-select" />
            
            </div>
            
            </div>
            

           
        </header>

        <CartModal active={activeBeltModal} closeModal={()=>{setActiveBeltModal(false)}} />
        
        
            <div className={isMobileActive ? `mobMnu mobMnuOpen` : `mobMnu`}>
                <img src={close} alt=""
                 className="mobMnu_close"
                 onClick={()=> setIsMobileActive(false)}
                  />
                <a href="/"><img src={logo} alt="" /></a>

                <p className="mb_list_title">{t("professional care")}</p>
                <ul className="mb_list">
                    <li><a href={`${i18n.language === "en" ? '/en' : ''}/category/profesijni-nabori`} onClick={()=> setIsMobileActive(false)}>{i18n.language === "en" ? 'PROFESSIONAL KITS' : 'професійні набори'}</a></li>
                    <li><a href={`${i18n.language === "en" ? '/en' : ''}/category/bazovi-preparati`} onClick={()=> setIsMobileActive(false)}>{i18n.language === "en" ? 'ESSENTIAL TREATMENTS' : 'базові препарти'}</a></li>
                    <li><a href={`${i18n.language === "en" ? '/en' : ''}/category/antioksidantni-pilingi`} onClick={()=> setIsMobileActive(false)}>{i18n.language === "en" ? 'Antioxidant Peelings' : 'антиоксидантні пілінги'}</a></li>
                </ul>
                <p className="mb_list_title">{t("home care")}</p>
                <ul className="mb_list">
                    
                    <li><a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-renaissance`} onClick={()=> setIsMobileActive(false)}>{i18n.language === "ua" && 'ЛІНІЯ'} RENAISSANCE {i18n.language === "ua" && 'LINE'} </a></li>
                    <li><a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-3r-ceramide`} onClick={()=> setIsMobileActive(false)}>{i18n.language === "ua" && 'ЛІНІЯ'}  3R CERAMIDE {i18n.language === "ua" && 'LINE'} </a></li>
                    <li><a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-b-biotic`} onClick={()=> setIsMobileActive(false)}>{i18n.language === "ua" && 'ЛІНІЯ'}  β-BIOTIC {i18n.language === "ua" && 'LINE'} </a></li>
                    <li><a href={`${i18n.language === "en" ? '/en' : ''}/category/liniya-aqya-o2xy`} onClick={()=> setIsMobileActive(false)}>{i18n.language === "ua" && 'ЛІНІЯ'}  AQYA O2XY {i18n.language === "ua" && 'LINE'} </a></li>
                    <li><a href={`${i18n.language === "en" ? '/en' : ''}/contact`} onClick={()=> setIsMobileActive(false)}>{i18n.language === "en" ? 'CONTACTS' : 'КОНТАКТИ'}</a></li>
                </ul>
            </div>
            {showSearch && <Search active={showSearch} closeModal={()=>{setShowSearch(false)}} />}
       
        </>
        
    )
}
export default Header;