
export const categoriesHome = [
    {id: 21, name: '3R CERAMIDE', slug: "liniya-3r-ceramide", type: "home"},
    {id: 23, name: 'AQYA O2XY', slug: "liniya-aqya-o2xy", type: "home"},
    {id: 20, name: 'RENAISSANCE', slug: "liniya-renaissance", type: "home"},
    {id: 22, name: 'BIOTIC', slug: "liniya-b-biotic", type: "home"}
]
export const categoriesProf = [
    {id: 29, name: 'Всі', slug: "bazovi-preparati", type:"profi", eng: "All"},
    {id: 24, name: 'Очищення', slug: "ochishhennya", type:"profi", eng: "Cleaning"},
    {id: 25, name: 'Пілінги', slug: "pilingi", type:"profi", eng: "Peeling"},
    {id: 31, name: 'Пудри-есенції', slug: "pudra", type:"profi", eng: "Powder essence"},
    {id: 25, name: 'Сироватки', slug: "sivorotki", type:"profi", eng: "Serum"},
    {id: 26, name: 'Креми', slug: "kremi", type:"profi", eng: "Cream"},
    {id: 27, name: 'Маски', slug: "maski", type:"profi", eng: "Mask"}
]

export const allCategories = [
    {id: 18, name: 'ПРОФЕСІЙНІ НАБОРИ', slug: "profesijni-nabori", type:"profi", eng: "Professional kits"},
    {id: 29, name: 'Базові препарати', slug: "bazovi-preparati", type:"profi", eng: "Essentials products"},
    {id: 27, name: 'Маски', slug: "maski", type:"profi", eng: "Mask"},
    {id: 24, name: 'Очищення', slug: "ochishhennya", type:"profi", eng: "Cleaning"},
    {id: 25, name: 'Сиворотки', slug: "sivorotki", type:"profi", eng: "Serum"},
    {id: 28, name: 'Домашній догляд', slug: "domashnij-doglyad", type: "home", eng: "Home care"},
    {id: 21, name: '3R CERAMIDE', slug: "liniya-3r-ceramide", type: "home", eng: "3R CERAMIDE"},
    {id: 23, name: 'AQYA O2XY', slug: "liniya-aqya-o2xy", type: "home", eng: "AQYA O2XY"},
    {id: 20, name: 'RENAISSANCE', slug: "liniya-renaissance", type: "home", eng: "RENAISSANCE"},
    {id: 22, name: 'BIOTIC', slug: "liniya-b-biotic", type: "home", eng: "BIOTIC"},
    {id: 30, name: 'Антиоксидантні пілінги', slug: "antioksidantni-pilingi", type: "profi", eng: "Antioxidant Peelings"}
]