import React, {useEffect, useRef, useState} from 'react'
import classes from './FilterComponent.module.scss'
import {ProductCard, FilterButton} from "@/Components"
import {useParams} from 'react-router-dom'
import { useActions } from '@/hooks/useActions'
import { categoriesHome, categoriesProf, allCategories } from '@/util/categories'
import { useTranslation  } from 'react-i18next'

type IProduct = {
    id: string,
    title: string,
    img: string,
    price: number,
    ml: string,
    tip_shkiri: string,
    categories: []
}

type FilterComponentProps = {
    products: IProduct[],
    isSetType: string
}

const FilterComponent:React.FC<FilterComponentProps> = ({products, isSetType}) => {
    const {t, i18n } = useTranslation()
    const [typeCat, setTypeCat] = useState<string>('')
    const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([])
    const { id } = useParams() as any
    const {setActiveCategory} = useActions()
    const isLoaded = useRef<boolean>(true);
    

    const findByCategory = (categiriesArr: any, catId: number) => {
        return categiriesArr.some((el: any) => el.id === catId);
    }

    const createSetList = (id: number) => {
        const filtered_cat_items = products?.filter(obj=> findByCategory(obj.categories, 18)) ;
     

        return <> 
            {filtered_cat_items?.map((el:any, i) =>  
                <ProductCard 
                    id={el.id}
                    key={i}
                    sku={el.sku}
                    slug={el.slug}
                    name={el.name}
                    img={el.images[0].src}
                    description={el.description}
                    meta={el.meta_data}
                    price={el.price} 
                    type={isSetType}
                    topQuick={false}
                    product={el}
                />
            )}
        </>
    }

    useEffect(()=>{
        isLoaded.current = true
        const result: any = allCategories.find(el=> el.slug === id)
        setTypeCat(result.type)
        const filtered_cat_items = products?.filter(obj=> findByCategory(obj.categories, result.id)) 
      
        setFilteredProducts(filtered_cat_items)
        setActiveCategory(result.slug)
        isLoaded.current = false
    }, [products, id])


    const filterProduct = (catId: number) => {
        // console.log(filteredProducts);
        // const filtered_cat_items = products?.filter(obj=> findByCategory(obj.categories, catId)) 
        // setFilteredProducts(filtered_cat_items)
        // console.log(filteredProducts);
        isLoaded.current = true
        setFilteredProducts([])
        const filtered_cat_items = products.filter(obj=> findByCategory(obj.categories, catId)) 
        setFilteredProducts([...filtered_cat_items])
        isLoaded.current = false
    }

    console.log(isLoaded.current);

  return (
    <div className="container">
        {/* {isLoaded.current ? <DNA
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                /> : ''} */}
        <div className={classes.filter_wrap}>
               
        {isSetType === 'profesijni-nabori' && (
            <div className={classes.filter_cat_box}>
                <p className={classes.filter_title}> <span> {i18n.language === "en" ? 'Professional' : 'Професійні'} </span>{i18n.language === "en" ? 'Kits' : 'набори'} </p>
                {createSetList(id)}
            </div>   
            ) 
        }
        {isSetType !== 'profesijni-nabori' && (
            <>
                {typeCat === 'home' &&  (<p className={classes.filter_title}> <span>{i18n.language === "en" ? 'Collection' : 'КОЛЕКЦІя'}</span> {i18n.language === "en" ? 'HOME CARE LINE' : 'ЛІНІЙ ДОМАШНЬОГО ДОГЛЯДУ'}  </p>)}
                {typeCat === 'profi' &&  (<p className={classes.filter_title}> <span> {i18n.language === "en" ? 'Essential Treatments' : 'Базові препарати'} </span>{i18n.language === "en" ? 'PROFESIONAL CARE LINE' : 'ЛІНІЙ професійного ДОГЛЯДУ'} </p>)}
            
                <div className={classes.filterBtnBox}>
                    {typeCat === 'home'  && (categoriesHome.map((el, i) => <FilterButton key={i} changeCat={()=>filterProduct(el.id)} catId={el.id} name={el.name} slug={el.slug} /> ))}
                    {typeCat === 'profi' && (categoriesProf.map((el, i) => <FilterButton key={i} changeCat={()=>filterProduct(el.id)} catId={el.id} 
                    name={i18n.language === "en" ? el.eng : el.name} slug={el.slug} /> ))}
                    
                </div>

                <div className={classes.filter_cat_box}>
                    {filteredProducts?.map((el:any, i) =>  
                            <ProductCard 
                                product={el}
                                key={i}
                                id={el.id}
                                sku={el.sku}
                                slug={el.slug}
                                name={el.name}
                                img={el?.images[0]?.src}
                                description={el.description}
                                meta={el.meta_data}
                                price={el.price} 
                                type={isSetType}
                                topQuick={false}
                            />
                            )}
                    </div> 
                
            </>
        
        ) }
            
        </div>
    </div>
  )
}
export default FilterComponent
